import React, { useState, useEffect, useLayoutEffect, useRef, useMemo } from "react";
import { Layout, Flex, Col, Row, Input, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';
import ButtonHome from '../../components/Buttons/ButtonHome';
import ButtonReactionAdd from '../../components/Buttons/ButtonReactionAdd';
import ButtonGlaz from '../../components/Buttons/ButtonGlaz';
import ButtonStoryAdd from '../../components/Buttons/ButtonStoryAdd';
import ButtonLoginOrProfile from '../../components/Buttons/ButtonLoginOrProfile';
import WiwHeaderTitle from '../../components/WiwHeaderTitle';
import ControlPanel from '../../components/WiwFooter/ControlPanel';
import PostUser from '../../components/PostUser';
import { BiBarChartAlt2 } from 'react-icons/bi';
import { MdOutlineSupervisedUserCircle } from 'react-icons/md';
import Voting from '../../components/WiwFooter/Voting';
import { getUser } from '../../utils/func';
import url from '../../utils/url';
import config from '../../utils/config';
import api from '../../services';
import { APP_ROUTES } from '../../utils/constant';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { handleImageUpload } from '../../utils/func';
import { SyncOutlined } from '@ant-design/icons';

const { Header, Content, Footer } = Layout;
const { TextArea } = Input;
const { storyAdd } = api;

export default function StoryAdd() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const user = getUser();
	const [storyTitle, setStoryTitle] = useState('');
	const [comment, setComment] = useState('');
	const [editorState, setEditorState] = useState(EditorState.createEmpty());
	const [scope, setScope] = useState(4);
	const [loading, setLoading] = useState(false);
	const [height_content, setHeightContent] = useState(0);
  	const [margin_content, setMarginContent] = useState('0px');
	const [messageApi, contextHolder] = message.useMessage();
	
	const ref_header= useRef(null);
	const ref_footer= useRef(null);

	function updateSize() {
		setTimeout(() => {
			setHeightContent(window.innerHeight-ref_header.current.clientHeight-ref_footer.current.clientHeight);
			setMarginContent(ref_header.current.clientHeight+'px');
		}, 100)
	}

	useLayoutEffect(() => {
		window.addEventListener('resize', updateSize);
		return () => window.removeEventListener('resize', updateSize);
	}, []);

  const handleChange = (data) => {
    setEditorState(data);
  };
  var htmlData = useMemo(
    () => draftToHtml(convertToRaw(editorState.getCurrentContent())),
    [editorState]
  );

  const toolbarOptions = {
    options: [/*"inline", "fontSize",*/ "image"],
    /*inline: {
      options: ["bold", "italic", "underline", "strikethrough"],
    },*/
    image: {
      urlEnabled: false,
      uploadEnabled: true, 
      uploadCallback: handleImageUpload, 
      previewImage: true,
      inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
      alignmentEnabled: false,
      defaultSize: {
        height: 'auto',
        width: '100%',
      },
    },
  };

	useEffect(() => {
		updateSize();
	}, []);

	useEffect(() => {
    let html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setComment(html);
  },[editorState]);

	const changeVoting = (scope) => {
		setScope(scope);
	}

	const onChangeTitle = (e) => {
		setStoryTitle(e.target.value);
		updateSize();
	}

	const cancel = ()=>{
    navigate(-1)
  }

	const sendStory = async () => {
		setLoading(true);
    const privat_flg = (localStorage.getItem(config.privat)) ? (localStorage.getItem(config.privat) === "true") : false;
    const response = await storyAdd({score: scope, title: storyTitle, txt_message: comment, privat: privat_flg});
		setLoading(false);
    if (response.statusCode == 401) {
      navigate(url(APP_ROUTES.LOGIN+'?from='+window.location.pathname), { replace: true });
      return;
    }
		if (response.hasOwnProperty("error")) {
			messageApi.open({
				type: 'error',
				content: response.error,
			});
			return;
		}
		if (response.new) {
			messageApi.open({
				type: 'success',
				content: t('story.new_story_add'),
			});
		} else {
			messageApi.open({
				type: 'info',
				content: t('story.new_story_merge'),
			});
		}
		setTimeout(function(){
			navigate(url(APP_ROUTES.REACTION+'/'+response.id), { replace: true });
		}, 2000);
  }

	return (
	<Layout style={{height:"100vh"}}>
		<Helmet>
			<title>{t('story.add')}</title>
			<meta name="description" content={t('story.add')} />
		</Helmet>
		{contextHolder}
		{
			loading &&
			<div className="story-add-loading">
				<SyncOutlined spin /> {t('story.find_news')}
			</div>
		}
		<Header className="header" ref={ref_header}>
			<WiwHeaderTitle title={t('story.add')}></WiwHeaderTitle>
			<div className="storyTitle fill">
				<Row type="flex" style={{ alignItems: 'center' }} justify="center">
					<Col className="align-center" span={3}>
							<BiBarChartAlt2 className="nav-icon button-disable" />
					</Col>
					<Col className="align-center" span={18} /*onClick={(e) => clickButton(e,'')}*/>
						<div className="storyTitle-title">
							<TextArea className="story_add_title" autoSize value={storyTitle} placeholder={t('story.title_placeholder')} onChange={onChangeTitle}/>
						</div>
					</Col>
					<Col className="align-center" span={3}>
							<MdOutlineSupervisedUserCircle className="nav-icon button-disable" />
					</Col>
				</Row>
    	</div>
		</Header>
		<Content className="content-layot" style={{ height: height_content+'px', top: margin_content}}>
			<div className="content-text">
				{user!==null && 
					<div className="addCommentPage">
					<PostUser author={user} scope={scope}/>
					{/*<TextArea className="addCommentText" value={storyText} placeholder={t('story.news_placeholder')} onChange={onChangeText}/>*/}
					<Editor
						editorState={editorState}
						onEditorStateChange={handleChange}
						wrapperClassName="editor-wrapper"
						editorClassName="message-editor"
						toolbarClassName="message-toolbar"
						toolbar={toolbarOptions}
        	/>
					</div>
				}
				<ControlPanel label="post.your_opinion" leftBtn="delete" rightBtn="send" onLeftAction={cancel} onRightAction={sendStory} />
			</div>
		</Content>
		<Footer ref={ref_footer}>
		<Voting onChange={changeVoting}/>
			<Flex justify="space-around" align="center" >
				<ButtonHome/>
				<ButtonStoryAdd/>
				<ButtonGlaz/>
				<ButtonReactionAdd disabled></ButtonReactionAdd>
				<ButtonLoginOrProfile/>
			</Flex>
		</Footer>
	</Layout>
	)
}