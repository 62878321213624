import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { useParams, useNavigate  } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import { Layout, Skeleton, Flex } from 'antd';
import {Helmet} from "react-helmet";
import WiwFooter from '../../components/WiwFooter';
import Voting from '../../components/WiwFooter/Voting';
import StoryTitle from '../../components/SrotyTitle';
import PostFilter from '../../components/PostFilter';
import PostStatistic from '../../components/PostStatistic';
import StoryUsers from '../../components/StoryUsers';
import PostContent from '../../components/PostContent';
import PostReactionAdd from '../../components/PostReactionAdd';
import ButtonHome from '../../components/Buttons/ButtonHome';
import ButtonReactionAdd from '../../components/Buttons/ButtonReactionAdd';
import ButtonGlaz from '../../components/Buttons/ButtonGlaz';
import ButtonStoryAdd from '../../components/Buttons/ButtonStoryAdd';
import ButtonLoginOrProfile from '../../components/Buttons/ButtonLoginOrProfile';
import api from '../../services'
import { APP_ROUTES } from '../../utils/constant';
import url from '../../utils/url';
import { addData, deleteData, getSwipeData, clearData, readReaction } from '../../utils/idb.ts';
import './post.scss';
import { useTranslation } from 'react-i18next';

const { Header, Content, Footer } = Layout;
const { postLoad, reactionsLoad, reactionsLoadRefresh, reactionView, reactionLoad, storyLoad } = api;

export default function Reaction() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const finish_data = {
      id: 1,
      scope: 0,
      story: {
        id: 1,
        title: t('post.finish_title'),
      },
      text_msg: t('post.finish_content'),
      rang: 0,
      user: {
        user_name: 'wiw',
        first_name: 'wiw',
        last_name: 'wiw'
      },
    }
    const clear_data = {
      text_msg: '', 
      user: {}
    }

    const [postState, setPostState] = useState({id: 0, scope: 0, story: {id: 0, title: ''}, text_msg: '', user: {}});
    const [loading, setLoading] = useState(true);
    //С окончание P это атрибуты страницы
    const [reactionIdP, setReactionIdP] = useState(0);
    const [sectionP, setSectionP] = useState('');
    const [finish, setFinish] = useState(false);
    const [queue, setQueue] = useState(1);
    const [height_content, setHeightContent] = useState(0);
    const [margin_content, setMarginContent] = useState('0px');
    const [addScope, setAddScope] = useState(4);
    const [swiped, setSwiped] = useState(true);
    const [pageTitle, setPageTitle] = useState('');
		const [pageDescription, setPageDescription] = useState('');
    let { storyId, section, reactionId } = useParams();
    storyId = !!storyId ? parseInt(storyId) : 0;
    reactionId = !!reactionId ? parseInt(reactionId) : 0;

    console.log("start ", storyId, reactionId, section);

    const ref_header= useRef(null);
    const ref_footer= useRef(null);
    const ref_content = useRef(null);

    const refPassthrough = (el) => {
      handlers.ref(el);
      ref_content.current = el;
    }

    const dataLoad = async () => {
      if (storyId!==0) {
        await loadStory();
      } else {
        await loadReactions();
      }
    }

    useEffect(() => {
      setReactionIdP(reactionId);
      setSectionP(section);
      async function handleLoad() {
        await dataLoad();
      }
      handleLoad();
    }, []);

    useEffect(() => {
      updateSize();
    }, [postState]);

    useEffect(() => {
      async function handleReactionLoad() {
        await dataLoad();
      }
      handleReactionLoad();
    }, [reactionId]);


		useEffect(() => {
			updateHelmet();
    }, [reactionId, section]);

    /**
		 * Формирование title, meta=description
		 */    
    function updateHelmet() {
      let title = postState.story.title;
			setPageTitle(title);
			let description = postState.story.title;
			setPageDescription(description);
    }

    function updateSize() {
      setTimeout(() => {
        setHeightContent(window.innerHeight-ref_header.current.clientHeight-ref_footer.current.clientHeight);
        setMarginContent(ref_header.current.clientHeight+'px');
      }, 100)
    }

    useLayoutEffect(() => {
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    
    const handlers = useSwipeable({
      onSwipedDown: async () => {
        nextReaction();
      },
      onSwipedUp: async () => {
        if(typeof sectionP !== "undefined") return;
        if (!swiped) return;
        let prev_queue = queue-1;
        if (prev_queue<1) prev_queue=1;
        await readReaction('reaction', reactionIdP, queue);
        setQueue(prev_queue);
        //await readReaction('reaction', reactionIdP, prev_queue);
        const prev = await getSwipeData('reaction', reactionIdP, prev_queue);
        if (prev.remains>1) {
          setFinish(false);
          deleteData('reaction', 1);
        } 
        setPostState(prev.reaction);
        setReactionIdP(prev.reaction.id);
        navigate(url(APP_ROUTES.REACTION+'/'+prev.reaction.id));
      },
      preventScrollOnSwipe: false
    })

    const nextReaction = async () => {
      if(typeof sectionP !== "undefined") return;
      if (!swiped) return;
      if (!finish) {
        reactionView({ reaction_id: reactionIdP });
        let next_queue = queue+1;
        await readReaction('reaction', reactionIdP, queue);
        setQueue(next_queue);
        const next = await getSwipeData('reaction', reactionIdP, next_queue);
        console.log(next);
        if (next.remains===0) {
          setFinish(true);
          setPostState(finish_data);
          await addData('reaction', [finish_data]);
        } else {
          setPostState(next.reaction);
          setReactionIdP(next.reaction.id);
          navigate(url(APP_ROUTES.REACTION+'/'+next.reaction.id));
          if (next.remains<=5) hideLoadReactions();
        }
      }
    }

    const loadReactions = async () => {
      setPostState({...postState, ...clear_data});
      console.log("REACTION load ", reactionId);
      setLoading(true);
      let response = null;
      if(reactionId===0) {
        console.log("reactionsLoadRefresh ", reactionId);
        response = await reactionsLoadRefresh();
      } else {
        console.log("reactionLoad by ID ", reactionId);
        response = await reactionLoad({id: reactionId});
      }
      if (response.success) {
        await clearData('reaction');
        await addData('reaction', response.list);
        if (response.list && response.list.length===0) {
          setFinish(true);
          setPostState(finish_data);
          await addData('reaction', [finish_data]);
        } else {
          setPostState(response.list[0]);
          setReactionIdP(response.list[0].id);
        }
        setLoading(false);
      } else {
        if (response.statusCode===404) navigate(APP_ROUTES.PAGE404, { replace: true });
        if (response.statusCode===403) navigate(APP_ROUTES.PAGE403, { replace: true });
      }
    }

    /**
     * Загрузка данных новости
     */
    const loadStory = async () => {
      setLoading(true);
      let response = await storyLoad({id: storyId});
      console.log(response);
      if (response.success) {
        setPostState({story: {id: response.id, title: response.title}});
        setLoading(false);
    } else {
      //if (response.statusCode===401) navigate(APP_ROUTES.LOGIN+'?from='+window.location.pathname, { replace: true });
    }
    }

    const hideLoadReactions = async () => {
      let response = await reactionsLoad();
      if (response.success) {
          await addData('reaction', response.list);
      } else {
        //if (response.statusCode===401) navigate(APP_ROUTES.LOGIN+'?from='+window.location.pathname, { replace: true });
      }
    }

    useEffect(() => {
      setSectionP(section);
    }, [section]);

    const changeSwipe = (val)=>{
      setSwiped(val);
    }

    function SwitchContent({value}) {
      switch (value) {
        case 'statistic':
          return <PostStatistic storyId={postState.story.id} />
        case 'reaction':
            return <StoryUsers storyId={postState.story.id} />
        case 'add':
            return <PostReactionAdd storyId={postState.story.id} reactionId = {reactionIdP} scope = {addScope} />
        default:
          return <PostContent reaction={postState} finish={finish} height={height_content} onChangeSwipe={changeSwipe} swiped={swiped} />
      }
    }

    const changeSection = (sect) => {
      if (sect === '') {
        navigate(url(APP_ROUTES.POST+'/'+reactionIdP));
      } else {
        navigate(url(APP_ROUTES.POST+'/'+postState.story.id+'/'+sect));
      }
      setSectionP(sect);
    }

    const changeVoting = (scope) => {
      setAddScope(scope);
    }

    return (
          <Layout style={{height:"100vh"}}>
            <Helmet>
              <title>{pageTitle}</title>
              <meta name="description" content={pageDescription} />
            </Helmet>
            <Header className="header" ref={ref_header}>
              <PostFilter/>
              <StoryTitle finish={finish} reactionId={postState.id} storyId={postState.story.id} title={postState.story.title} changeSection={changeSection}></StoryTitle>
            </Header>
            <Content {...handlers} ref={refPassthrough} className="content-layot" style={{ height: height_content+'px', top: margin_content}}>
              {loading && <div className="sceleton-reaction-load"><Skeleton active /></div>}
              <SwitchContent value={sectionP} />
            </Content>
            {/*<WiwFooter postId={postState.story.id}></WiwFooter>*/}

            <Footer ref={ref_footer}>
            <Voting storyId={postState.story.id} reactionId={postState.id} onChange={changeVoting}/>
            <Flex justify="space-around" align="center" >
              <ButtonHome/>
              <ButtonStoryAdd/>
              <ButtonGlaz/>
              <ButtonReactionAdd storyId={postState.story.id}></ButtonReactionAdd>
              <ButtonLoginOrProfile/>
            </Flex>
            </Footer>
          </Layout>
    )
}