import React, { useEffect, useState, useMemo } from "react";
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../utils/constant';
import PostUser from '../PostUser';
import ControlPanel from '../WiwFooter/ControlPanel';
import { getUser } from '../../utils/func';
import url from '../../utils/url';
import api from '../../services';
import { useTranslation } from 'react-i18next';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { handleImageUpload, getPrivatFlg } from '../../utils/func';
import { SyncOutlined } from '@ant-design/icons';

const { queryPostVoting } = api;

export default function PostReactionAdd({storyId, reactionId, scope=4}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = getUser();
  const [keyComment] = useState('comment'+storyId);
  const [comment, setComment] = useState('');
  const [loading, setLoading] = useState(false);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const handleChange = (data) => {
    setEditorState(data);
  };
  var htmlData = useMemo(
    () => draftToHtml(convertToRaw(editorState.getCurrentContent())),
    [editorState]
  );

  const toolbarOptions = {
    options: [/*"inline", "fontSize",*/ "image"],
    /*inline: {
      options: ["bold", "italic", "underline", "strikethrough"],
    },*/
    image: {
      urlEnabled: false,
      uploadEnabled: true, 
      uploadCallback: handleImageUpload, 
      previewImage: true,
      inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
      alignmentEnabled: false,
      defaultSize: {
        height: 'auto',
        width: '100%',
      },
    },
  };

  useEffect(() => {
    if (user === null) {
      navigate(url(`${APP_ROUTES.LOGIN}?from=${window.location.pathname}`), { replace: true });   
    }
    if (localStorage.getItem(keyComment)) {
      //setEditorState(localStorage.getItem(keyComment));
      setComment(localStorage.getItem(keyComment));
    }
  },[])

  const sendVoting = async () => {
    setLoading(true);
    const privat_flg = getPrivatFlg();
    const response = await queryPostVoting({post_id: Number(storyId), parent_reaction: reactionId, score: scope, txt_message: comment, privat: privat_flg});
    setLoading(false);
    if (response.statusCode == 401) {
      navigate(url(APP_ROUTES.LOGIN+'?from='+window.location.pathname), { replace: true });
      return;
    }
    localStorage.removeItem(keyComment);
    navigate(url(APP_ROUTES.REACTION+'/'+response.id), { replace: true });
    console.log(response);
  }

  const cancel = ()=>{
    navigate(-1)
  }

  useEffect(() => {
    let html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setComment(html);
    localStorage.setItem(keyComment, html);
  },[editorState]);

  
  return (
    <div className="content-text">
      {
        loading &&
        <div className="story-add-loading">
          <SyncOutlined spin /> {t('reaction.checking')}
        </div>
		  }
      {user!==null && 
        <div className="addCommentPage">
          <PostUser author={user} scope={scope}/>
        <Editor
          editorState={editorState}
          onEditorStateChange={handleChange}
          wrapperClassName="editor-wrapper"
          editorClassName="message-editor"
          toolbarClassName="message-toolbar"
          toolbar={toolbarOptions}
        />
        {/*<div className="html-output">{comment}</div>*/}
        </div>
      }
      
      {/*<input type="file" accept="image/*" onChange={event => handleImageUpload(event)}/>
      <img src={img} style={{width: '100%'}}/>*/}
      <ControlPanel label="post.opinion_news" leftBtn="pensil" rightBtn="send" onLeftAction={cancel} onRightAction={sendVoting} />
    </div>
  )
}