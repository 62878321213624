import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { Layout, Flex, Col, Row, Button, Input, Upload, Radio } from 'antd';
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';
import ButtonHome from '../../components/Buttons/ButtonHome';
import ButtonReactionAdd from '../../components/Buttons/ButtonReactionAdd';
import ButtonGlaz from '../../components/Buttons/ButtonGlaz';
import ButtonStoryAdd from '../../components/Buttons/ButtonStoryAdd';
import ButtonLoginOrProfile from '../../components/Buttons/ButtonLoginOrProfile';
import WiwHeaderTitle from '../../components/WiwHeaderTitle';
import UserImage from '../../components/UserImage';
import ControlButton from '../../components/WiwFooter/ControlButton';
import { IoAtOutline } from "react-icons/io5";
import { getUser } from '../../utils/func';
import api from '../../services';
import { APP_ROUTES } from '../../utils/constant';
import { handleImageUpload } from '../../utils/func';

const { Header, Content, Footer } = Layout;
const { userSettings, userUpdateFields, getLanguage } = api;

export default function Settings() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	let storageUser = getUser();
	const [user, setUser] = useState({});
	const [user_name, setUserName] = useState(storageUser.user_name);
	const [first_name, setFirstname] = useState(storageUser.first_name);
	const [picture, setPicture] = useState(storageUser.picture);
	const [language, setLanguage] = useState('');
	const [disabledBtn, setDisabledBtn] = useState(true);
	const [lng_options, setLngOptions] = useState([]);
	const [height_content, setHeightContent] = useState(0);
  const [margin_content, setMarginContent] = useState('0px');

	const ref_header= useRef(null);
	const ref_footer= useRef(null);

	function updateSize() {
		setTimeout(() => {
			setHeightContent(window.innerHeight-ref_header.current.clientHeight-ref_footer.current.clientHeight);
			setMarginContent(ref_header.current.clientHeight+'px');
		}, 100)
	}

	useLayoutEffect(() => {
		window.addEventListener('resize', updateSize);
		return () => window.removeEventListener('resize', updateSize);
	}, []);

	useEffect(() => {
		function handleLoadData () {
			updateSize();
			loadSettings();
			loadLanguages();
		}
		handleLoadData();
	}, []);

	const loadSettings = async () => {
		let response = null;
		response = await userSettings();
		if (response.success) {
			if (response.user===null) {
				navigate(APP_ROUTES.PAGE403, { replace: true });
				return;
			}
			setUser(response.user);
			setUserName(response.user.user_name);
			setFirstname(response.user.first_name);
			setPicture(response.user.picture);
		}
	}

	const loadLanguages = async () => {
		let response = null;
		response = await getLanguage();
		if (response.success) {
			let options = [];
			response.list.forEach(l => {
				options.push({ label: l.button_text, value: l.id });
			})
			setLngOptions(options);
		}
	}

	const changeUsername = (e)=>{
    setUserName(e.target.value);
  }

	const changeFirstname = (e)=>{
    setFirstname(e.target.value);
  }

	const changeLanguage = (e)=>{
		setLanguage(e.target.value);
	}

	const handleImage = async (e)=> {
		const base = await handleImageUpload(e);
		setPicture(base.data.link);
	}

	useEffect(() => {
		checkDisabled();
 	}, [picture, language, first_name, user_name]);

	const goBack = ()=>{
		navigate(-1);
	}

	const checkDisabled = () => {
		let d = true;
		if (user.user_name!==user_name) d = false;
		if (user.first_name!==first_name) d = false;
		if (user.picture!==picture) d = false;
		setDisabledBtn(d);
	}

	const saveSettings = async ()=>{
		let data = {};
		if (user.first_name!==first_name) {
			data['first_name'] = first_name;
		}
		if (user.user_name!==user_name) {
			data['user_name'] = user_name;
		}
		if (user.picture!==picture) {
			data['picture'] = picture;
		}
		if (Object.keys(data).length === 0) {
			navigate(-1);
		} else {
			data['id'] = user.id;
			setDisabledBtn(true);
			let response = await userUpdateFields(data);
			if (response.success) {
				localStorage.setItem('user', JSON.stringify(response.user));
			}
		}
	}

  return (
    <Layout style={{height:"100vh"}}>
			<Helmet>
        <title>{t('user.settings')}</title>
        <meta name="description" content={t('user.settings')} />
      </Helmet>
			<Header className="header" ref={ref_header}>
				<WiwHeaderTitle title={t('user.settings')}></WiwHeaderTitle>
      </Header>
			<Content className="content-layot" style={{ height: height_content+'px', top: margin_content}}>
				<div className="profile-settings">
					<Input placeholder={t('user.placeholder_username')} value={user_name} prefix={<IoAtOutline />} className="wiw-input" onChange={changeUsername} />
					<Input placeholder={t('user.placeholder_name')} value={first_name} className="wiw-input" onChange={changeFirstname} />
					<Upload
						name="picture"
						className="wiw-input picture-input"
						showUploadList={false}
						action={handleImage}
						accept='image/gif,image/jpeg,image/jpg,image/png,image/svg'
					>
						<UserImage picture={picture}/>
					</Upload>
					<p>Language</p>
					<div className="lng-radio">
							<Radio.Group name="language" options={lng_options} value={language} optionType="button" onChange={changeLanguage} />
					</div>
				</div>
			</Content>
			<Footer ref={ref_footer}>
				<ControlButton onCancel={goBack} onOK={saveSettings} disabledOk={disabledBtn}/>
        <Flex justify="space-around" align="center" >
          <ButtonHome/>
          <ButtonStoryAdd/>
          <ButtonGlaz disabled/>
          <ButtonReactionAdd disabled></ButtonReactionAdd>
          <ButtonLoginOrProfile/>
        </Flex>
      </Footer>
    </Layout>
  )
}