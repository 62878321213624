import React from "react";
import { Flex, Button } from 'antd';
import { MdCheckCircleOutline, MdOutlineCancel } from "react-icons/md";
import './index.scss';

export default function ControlButton({onOK, onCancel, disabledOk=false}) {
	return (
		<Flex className="control-button" justify="space-around" align="center" >
			<Button icon={<MdOutlineCancel className="nav-icon"/>} onClick={onCancel}/>
			<Button icon={<MdCheckCircleOutline className="nav-icon"/>} onClick={onOK} disabled={disabledOk}/>
		</Flex>
	)
}