import imageCompression from 'browser-image-compression';
import config from './config';

export function getLanguage() {
    let language = localStorage.getItem("i18nextLng");
    if (language===null) {
        language='en'
    }
    return language;
}

export function getUser() {
    let user = localStorage.getItem("user");
    if (user !== null)  user = JSON.parse(user);
    return user;
}

/**
 * 
 * @returns флаг приватности для добавляемых реакций
 */
export function getPrivatFlg() {
  const privat_flg = (localStorage.getItem(config.privat)) ? (localStorage.getItem(config.privat) === "true") : false;
  return privat_flg;
}

export function createName (author) {
    let name  = '';
    if (!author) return name;
    if (!!author.first_name) name+=author.first_name;
    if (name!=='') name+=' ';
    if (!!author.last_name) name+=author.last_name;
    return name;
}

export function generateFToken() {
    var d = new Date().getTime();
    var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16;//random number between 0 and 16
        if(d > 0){//Use timestamp until depleted
            r = (d + r)%16 | 0;
            d = Math.floor(d/16);
        } else {//Use microseconds since page-load if supported
            r = (d2 + r)%16 | 0;
            d2 = Math.floor(d2/16);
        }
        // eslint-disable-next-line no-mixed-operators
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
}

/**
 * 
 * @param {*} array - массив
 * @param {*} key - ключ
 * @param {null|'asc'|'desc'} by - направление сортировки
 * @returns - отсортированный массив
 */
export function sort_by_key(array, key, by)
{
 return array.sort(function(a, b)
 {
  var x = a[key]; var y = b[key];
  if (by && by==='desc') return ((x > y) ? -1 : ((x < y) ? 1 : 0));
  return ((x < y) ? -1 : ((x > y) ? 1 : 0));
 });
}

//преобразование загруженной картинки в base64
export async function handleImageUpload(/*event*/ imageFile) {

    //const imageFile = event.target.files[0];
    //console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
    console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);
      
    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1080,
      useWebWorker: true,
    }
    try {
      const compressedFile = await imageCompression(imageFile, options);
      //console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
      console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
      //console.log(compressedFile);
      const base = await imageCompression.getDataUrlFromFile(compressedFile);
      //console.log(base);

      return new Promise((resolve) => {
        resolve({
          data: {
            link: base,
          },
        });
      });
      
      //await uploadToServer(compressedFile); // write your own logic
    } catch (error) {
      console.log(error);
    }
}